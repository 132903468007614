import React from 'react';
import {useDispatch} from 'react-redux';
import {Box, Stack, useBreakpoints} from 'modern-famly';

import translate from 'signin-app/helpers/translate';
import {pinHolder} from 'signin-app/pin/selectors';
import {
    ConfirmButton,
    ActionButton,
    BottomBarContainer,
    ConfirmButtonTypes,
    ActionOnClickButton,
} from 'signin-app/components/action-buttons';
import {useTypedSelector} from 'signin-app/components/hooks';

import {checkOutChild} from './actions';
import * as Selectors from './selectors';
import {useCheckOutChild} from './check-out-child-context';

interface CheckOutSubmitProps {
    showSwitchRoomView: VoidFunction;
}

export const CheckOutSubmit: React.FC<CheckOutSubmitProps> = props => {
    const {child, displayName} = useCheckOutChild();
    const {showSwitchRoomView} = props;
    const isCheckingOut = useTypedSelector(Selectors.isCheckingOut);
    const pin = useTypedSelector(pinHolder);
    const dispatch = useDispatch();
    const canSwitchRoom = useTypedSelector(Selectors.canSwitchRoom);
    const {isTabletPortraitAndLarger} = useBreakpoints();

    const handleChildCheckOut = React.useCallback(() => {
        dispatch(checkOutChild.action(child.id, pin));
    }, [dispatch, child.id, pin]);

    return (
        <BottomBarContainer>
            <Stack direction="column" width="100%" gap={3}>
                <Stack gap={4}>
                    {canSwitchRoom && (
                        <ActionOnClickButton
                            text={translate('movePersonToRoom', {personName: displayName})}
                            onClick={showSwitchRoomView}
                            compact={!isTabletPortraitAndLarger}
                        />
                    )}
                    <ActionButton
                        text={translate('changePickUp')}
                        link={`/child/${child.id}/checkin?update`}
                        compact={!isTabletPortraitAndLarger && canSwitchRoom}
                    />
                </Stack>
                <Box>
                    <ConfirmButton
                        type={ConfirmButtonTypes.SIGN_OUT}
                        text={translate('signOut', {personName: displayName})}
                        isLoading={isCheckingOut}
                        onClick={handleChildCheckOut}
                        dataE2eCheckinConfirmButton="child-check-out-confirm-button"
                    />
                </Box>
            </Stack>
        </BottomBarContainer>
    );
};
