import {type TrackingEvent} from '../types';

export const GrowthEvents = {
    // Organization request new site
    ORG_REQUEST_NEW_SITE_BUTTON_CLICKED: {
        name: 'Add site button clicked',
        sendTo: ['Mixpanel'],
        isActive: true,
        expirationDate: 'none',
    },
    ORG_REQUEST_NEW_SITE_SUCCESS: {
        name: 'Add site modal success',
        sendTo: ['Mixpanel'],
        isActive: true,
        expirationDate: 'none',
    },
    ORG_REQUEST_SITE_MODAL: {name: 'Add site modal', sendTo: ['Mixpanel'], isActive: true, expirationDate: 'none'},

    // Support
    SUPPORT_OPENED: {name: 'Support opened', sendTo: ['Mixpanel'], isActive: true, expirationDate: 'none'},

    // Famly store
    PRICING_PAGE_OPENED: {
        name: 'Famly store: Pricing page opened',
        sendTo: ['Mixpanel'],
        isActive: true,
        expirationDate: 'none',
    },
    FAMLY_STORE_OPENED: {
        name: 'Famly store: Famly store page opened',
        sendTo: ['Mixpanel'],
        isActive: true,
        expirationDate: 'none',
    },
    FAMLY_STORE_MODAL_BUTTON_CLICKED: {
        name: 'Famly store: modal button clicked',
        sendTo: ['Mixpanel'],
        isActive: true,
        expirationDate: 'none',
    },
    FAMLY_STORE_MODAL_REQUEST_PACKAGE_TRIAL_BUTTON_CLICKED: {
        name: 'Famly store: Request package trial button clicked',
        sendTo: ['Mixpanel'],
        isActive: true,
        expirationDate: 'none',
    },
    FAMLY_STORE_START_PACKAGE_TRIAL_BUTTON_CLICKED: {
        name: 'Famly store: Start package trial button clicked',
        sendTo: ['Mixpanel'],
        isActive: true,
        expirationDate: 'none',
    },
    FAMLY_STORE_UPGRADE_PACKAGE_BUTTON_CLICKED: {
        name: 'Famly store: Upgrade package button clicked',
        sendTo: ['Mixpanel'],
        isActive: true,
        expirationDate: 'none',
    },
    FAMLY_STORE_BUY_NOW_BUTTON_CLICKED: {
        name: 'Famly store: Buy now button clicked',
        sendTo: ['Mixpanel'],
        isActive: true,
        expirationDate: 'none',
    },
    FAMLY_STORE_BUY_PACKAGE_FORM_BUTTON_CLICKED: {
        name: 'Famly store: buy package form - button clicked',
        sendTo: ['Mixpanel'],
        isActive: true,
        expirationDate: 'none',
    },
    FAMLY_STORE_BUY_PACKAGE_FORM_CONFIRM_PURCHASE: {
        name: 'Famly store: buy package form - confirm purchase',
        sendTo: ['Mixpanel'],
        isActive: false,
        expirationDate: 'none',
    },
    FAMLY_STORE_CONFIRM_UPSELL: {
        name: 'Famly store: Confirm upsell',
        sendTo: ['Mixpanel'],
        isActive: true,
        expirationDate: 'none',
    },
    FAMLY_STORE_NEW_PACKAGE_MODAL_BUTTON_CLICKED: {
        name: 'Famly store: New package modal button clicked',
        sendTo: ['Mixpanel'],
        isActive: true,
        expirationDate: 'none',
    },
    FAMLY_STORE_BOOSTER_PACK_EXPANDED: {
        name: 'Famly store: Booster pack expanded',
        sendTo: ['Mixpanel'],
        isActive: true,
        expirationDate: 'none',
    },
    FAMLY_STORE_LINK_FOR_PACKAGE_COMPARISON_TABLE_OPENED: {
        name: 'Famly store: Link for package comparison table opened',
        sendTo: ['Mixpanel'],
        isActive: true,
        expirationDate: 'none',
    },
    FAMLY_STORE_TAB_CLICKED: {
        name: 'Famly store: Tab clicked',
        sendTo: ['Mixpanel'],
        isActive: true,
        expirationDate: 'none',
    },
    FAMLY_STORE_PURCHASE_SUCCEEDED: {
        name: 'Famly store: purchase succeeded',
        sendTo: ['Mixpanel'],
        isActive: true,
        expirationDate: 'none',
    },
    FAMLY_STORE_CARD_MODAL_OPENED: {
        name: 'Famly store: Card modal opened',
        sendTo: ['Mixpanel'],
        isActive: true,
        expirationDate: 'none',
    },
    FAMLY_STORE_COMPARISON_TABLE_OPENED: {
        name: 'Famly store: Comparison table opened',
        sendTo: ['Mixpanel'],
        isActive: true,
        expirationDate: 'none',
    },
    FAMLY_STORE_CHECKOUT: {
        name: 'Famly store: Checkout',
        sendTo: ['Mixpanel'],
        isActive: true,
        expirationDate: 'none',
    },
    FAMLY_STORE_TEMPLATE_DOWNLOADED: {
        name: 'Famly store: Free template downloaded',
        sendTo: ['Mixpanel'],
        isActive: true,
        expirationDate: 'none',
    },

    // Trial customers, buy now banner
    BUY_NOW_BANNER_SHOWN: {
        name: 'Buy now banner: Banner shown',
        sendTo: ['Mixpanel'],
        isActive: true,
        expirationDate: 'none',
    },
    BUY_NOW_BANNER_BUY_NOW_CLICKED: {
        name: 'Buy now banner: Buy now clicked',
        sendTo: ['Mixpanel'],
        isActive: true,
        expirationDate: 'none',
    },
    BUY_NOW_BANNER_REMIND_ME_LATER_CLICKED: {
        name: 'Buy now banner: Remind me later clicked',
        sendTo: ['Mixpanel'],
        isActive: true,
        expirationDate: 'none',
    },
    BUY_NOW_BANNER_DISSMISS_CLICKED: {
        name: 'Buy now banner: Dismiss clicked',
        sendTo: ['Mixpanel'],
        isActive: true,
        expirationDate: 'none',
    },

    // Finance onboarding wizard
    FINANCE_ONBOARDING_GET_STARTED: {
        name: 'Finance onboarding: Get started',
        sendTo: ['Mixpanel'],
        isActive: true,
        expirationDate: 'none',
    },
    FINANCE_ONBOARDING_INVOICING_SCHEDULES: {
        name: 'Finance onboarding: Invoicing schedules',
        sendTo: ['Mixpanel'],
        isActive: true,
        expirationDate: 'none',
    },
    FINANCE_ONBOARDING_PRICING_AGE_BANDS: {
        name: 'Finance onboarding: Pricing age bands',
        sendTo: ['Mixpanel'],
        isActive: true,
        expirationDate: 'none',
    },
    FINANCE_ONBOARDING_SESSIONS_AND_PACKAGES: {
        name: 'Finance onboarding: Sessions and packages',
        sendTo: ['Mixpanel'],
        isActive: true,
        expirationDate: 'none',
    },
    FINANCE_ONBOARDING_EXTRA_CHARGES: {
        name: 'Finance onboarding: Extra charges',
        sendTo: ['Mixpanel'],
        isActive: true,
        expirationDate: 'none',
    },
    FINANCE_ONBOARDING_SUBMITTED: {
        name: 'Finance onboarding: succesfully submitted',
        sendTo: ['Mixpanel'],
        isActive: true,
        expirationDate: 'none',
    },
    FINANCE_ONBOARDING_DONE: {
        name: 'Finance onboarding: done',
        sendTo: ['Mixpanel'],
        isActive: true,
        expirationDate: 'none',
    },
    FINANCE_ONBOARDING_QUIT: {
        name: 'Finance onboarding: quit',
        sendTo: ['Mixpanel'],
        isActive: true,
        expirationDate: 'none',
    },
    FINANCE_ONBOARDING_TUITION: {
        name: 'Finance onboarding: Tuition',
        sendTo: ['Mixpanel'],
        isActive: true,
        expirationDate: 'none',
    },
    FINANCE_ONBOARDING_CALENDAR: {
        name: 'Finance onboarding: Calendar',
        sendTo: ['Mixpanel'],
        isActive: true,
        expirationDate: 'none',
    },
    FINANCE_ONBOARDING_PRICING_RULES: {
        name: 'Finance onboarding: Pricing rules',
        sendTo: ['Mixpanel'],
        isActive: true,
        expirationDate: 'none',
    },

    // "What is Famly for parents?" experiment
    WHAT_IS_FAMLY_FOR_PARENTS_LINK_CLICKED: {
        name: '"What is Famly for parents?" link clicked',
        sendTo: ['Mixpanel'],
        isActive: true,
        expirationDate: 'none',
    },

    // Institution settings
    INSTITUTION_SETTINGS_GET_HELP_WITH_FINANCES_MODAL_OPENED: {
        name: 'Help finance setup modal: opened',
        sendTo: ['Mixpanel'],
        isActive: true,
        expirationDate: 'none',
    },
    INSTITUTION_SETTINGS_GET_HELP_WITH_FINANCES_MODAL_BUTTON_CLICKED: {
        name: 'Help finance setup modal: button clicked',
        sendTo: ['Mixpanel'],
        isActive: true,
        expirationDate: 'none',
    },

    // Parent Onboarding
    PARENT_ONBOARDING_SHOWN: {
        name: 'Parent onboarding: shown',
        sendTo: ['Mixpanel'],
        isActive: true,
        expirationDate: 'none',
    },
    PARENT_ONBOARDING_CLOSE: {
        name: 'Parent onboarding: close',
        sendTo: ['Mixpanel'],
        isActive: true,
        expirationDate: 'none',
    },
    PARENT_ONBOARDING_CLICK: {
        name: 'Parent onboarding: click',
        sendTo: ['Mixpanel'],
        isActive: true,
        expirationDate: 'none',
    },

    // Feature limitations
    DISCOVERED_FEATURE_MODAL_CLICK: {
        name: 'Discovered feature modal: click',
        sendTo: ['Mixpanel'],
        isActive: true,
        expirationDate: 'none',
    },

    // "Show me how this works"
    SHOW_ME_HOW_THIS_WORKS: {
        name: 'Show me how this works: button clicked',
        sendTo: ['Mixpanel'],
        isActive: true,
        expirationDate: 'none',
    },

    // Subscription page
    SUBSCRIPTION_PAGE_EDITED_RECIPIENT: {
        name: 'Subscription page: Edit recipient',
        sendTo: ['Mixpanel'],
        isActive: true,
        expirationDate: 'none',
    },
    SUBSCRIPTION_PAGE_PAID_BALANCE: {
        name: 'Subscription page: Pay balance',
        sendTo: ['Mixpanel'],
        isActive: true,
        expirationDate: 'none',
    },
    SUBSCRIPTION_PAGE_BUTTON_CLICKED: {
        name: 'Subscription page: Click button',
        sendTo: ['Mixpanel'],
        isActive: true,
        expirationDate: 'none',
    },
    SUBSCRIPTION_PAGE_INVOICE_VIEWED: {
        name: 'Subscription page: View invoice',
        sendTo: ['Mixpanel'],
        isActive: true,
        expirationDate: 'none',
    },
    SUBSCRIPTION_PAGE_VIEWED: {
        name: 'Subscription page: Show page',
        sendTo: ['Mixpanel'],
        isActive: true,
        expirationDate: 'none',
    },

    // Signup trial flow
    SIGNUP_FLOW: {name: 'Signup flow', sendTo: ['Mixpanel'], isActive: true, expirationDate: 'none'},
    SIGNUP_FLOW_COMPLETED: {
        name: 'Signup flow: Completed',
        sendTo: ['Mixpanel'],
        isActive: true,
        expirationDate: 'none',
    },
    SIGNUP_FLOW_SET_PASSWORD_MODAL: {
        name: 'Signup flow: Set password modal',
        sendTo: ['Mixpanel'],
        isActive: true,
        expirationDate: 'none',
    },

    // Setup guide
    SETUP_GUIDE_WIDGET_EXPANDED: {
        name: 'Setup guide: Widget expanded',
        sendTo: ['Mixpanel'],
        isActive: true,
        expirationDate: 'none',
    },
    SETUP_GUIDE_WIDGET_DISMISSED: {
        name: 'Setup guide: Widget dismissed',
        sendTo: ['Mixpanel'],
        isActive: true,
        expirationDate: 'none',
    },
    SETUP_GUIDE_CATEGORY_CLICKED: {
        name: 'Setup guide: Category clicked',
        sendTo: ['Mixpanel'],
        isActive: true,
        expirationDate: 'none',
    },
    SETUP_GUIDE_SECTION_CLICKED: {
        name: 'Setup guide: Section clicked',
        sendTo: ['Mixpanel'],
        isActive: true,
        expirationDate: 'none',
    },
    SETUP_GUIDE_QUESTION_BUTTON_CLICKED: {
        name: 'Setup guide: Question button clicked',
        sendTo: ['Mixpanel'],
        isActive: true,
        expirationDate: 'none',
    },
    SETUP_GUIDE_QUESTION_OPENING_HOURS_ANSWERED: {
        name: 'Setup guide: Question "Opening hours" answered',
        sendTo: ['Mixpanel'],
        isActive: true,
        expirationDate: 'none',
    },
    SETUP_GUIDE_QUESTION_AGE_GROUPS_ANSWERED: {
        name: 'Setup guide: Question "Age groups" answered',
        sendTo: ['Mixpanel'],
        isActive: true,
        expirationDate: 'none',
    },
    SETUP_GUIDE_QUESTION_AGE_GROUPS_ADDED_NEW_AGE_GROUP: {
        name: 'Setup guide: Question "Age groups" added new age group',
        sendTo: ['Mixpanel'],
        isActive: true,
        expirationDate: 'none',
    },
    SETUP_GUIDE_QUESTION_AGE_GROUPS_EDITED_AGE_GROUP: {
        name: 'Setup guide: Question "Age groups" edited age group',
        sendTo: ['Mixpanel'],
        isActive: true,
        expirationDate: 'none',
    },
    SETUP_GUIDE_QUESTION_AGE_GROUPS_REMOVED_AGE_GROUP: {
        name: 'Setup guide: Question "Age groups" removed age group',
        sendTo: ['Mixpanel'],
        isActive: true,
        expirationDate: 'none',
    },
    SETUP_GUIDE_QUESTION_ASSESSMENTS_ANSWERED: {
        name: 'Setup guide: Question "Assessments" answered',
        sendTo: ['Mixpanel'],
        isActive: true,
        expirationDate: 'none',
    },
    SETUP_GUIDE_QUESTION_COUNTRY_ANSWERED: {
        name: 'Setup guide: Question "country" answered',
        sendTo: ['Mixpanel'],
        isActive: true,
        expirationDate: 'none',
    },
    SETUP_GUIDE_QUESTION_CURRICULUM_BUILDER_ANSWERED: {
        name: 'Setup guide: Question "curriculum builder" answered',
        sendTo: ['Mixpanel'],
        isActive: true,
        expirationDate: 'none',
    },
    SETUP_GUIDE_QUESTION_FOLLOWS_STATEMENT_BASED_TRACKING_ANSWERED: {
        name: 'Setup guide: Question "follows statement based tracking" answered',
        sendTo: ['Mixpanel'],
        isActive: true,
        expirationDate: 'none',
    },
    SETUP_GUIDE_QUESTION_LEARNING_FRAMEWORKS_ANSWERED: {
        name: 'Setup guide: Question "learning frameworks" answered',
        sendTo: ['Mixpanel'],
        isActive: true,
        expirationDate: 'none',
    },
    SETUP_GUIDE_QUESTION_REFERENCE_MATERIALS_ANSWERED: {
        name: 'Setup guide: Question "reference materials" answered',
        sendTo: ['Mixpanel'],
        isActive: true,
        expirationDate: 'none',
    },
    SETUP_GUIDE_QUESTION_PARENT_OBSERVATIONS_ANSWERED: {
        name: 'Setup guide: Question "parent observations" answered',
        sendTo: ['Mixpanel'],
        isActive: true,
        expirationDate: 'none',
    },
    SETUP_GUIDE_QUESTION_INVOICE_DEADLINE_ANSWERED: {
        name: 'Setup guide: Question "invoice deadline" answered',
        sendTo: ['Mixpanel'],
        isActive: true,
        expirationDate: 'none',
    },

    SETUP_GUIDE_QUESTION_INVOICE_SCHEDULE_ANSWERED: {
        name: 'Setup guide: Question "invoice schedule" answered',
        sendTo: ['Mixpanel'],
        isActive: true,
        expirationDate: 'none',
    },

    SETUP_GUIDE_QUESTION_INVOICE_SCHEDULE_FOR_NEW_BILL_PAYERS_ANSWERED: {
        name: 'Setup guide: Question "invoice schedule for new bill payers" answered',
        sendTo: ['Mixpanel'],
        isActive: true,
        expirationDate: 'none',
    },

    SETUP_GUIDE_QUESTION_INVOICE_PERIOD_ANSWERED: {
        name: 'Setup guide: Question "invoice period" answered',
        sendTo: ['Mixpanel'],
        isActive: true,
        expirationDate: 'none',
    },
    SETUP_GUIDE_QUESTION_WHO_SIGNS_CHILDREN_IN_ANSWERED: {
        name: 'Setup guide: Who signs children in answered',
        sendTo: ['Mixpanel'],
        isActive: true,
        expirationDate: 'none',
    },
    SETUP_GUIDE_QUESTION_DO_YOU_WANT_TO_USE_SIGN_IN_KIOSK_ANSWERED: {
        name: 'Setup guide: Do you want to use sign in kiosk answered',
        sendTo: ['Mixpanel'],
        isActive: true,
        expirationDate: 'none',
    },
    SETUP_GUIDE_SIGN_IN_OPTION_CHOSEN: {
        name: 'Setup guide: Sign in option chosen',
        sendTo: ['Mixpanel'],
        isActive: true,
        expirationDate: 'none',
    },
    SETUP_GUIDE_QUESTION_GPS_ANSWERED: {
        name: 'Setup guide: GPS question answered',
        sendTo: ['Mixpanel'],
        isActive: true,
        expirationDate: 'none',
    },
    SETUP_GUIDE_QUESTION_KIOSK_DEVICE_ANSWERED: {
        name: 'Setup guide: Kiosk device',
        sendTo: ['Mixpanel'],
        isActive: true,
        expirationDate: 'none',
    },
    SETUP_GUIDE_QUESTION_WHO_SHOULD_SEE_SEEN_BY_RECEIPT_ANSWERED: {
        name: 'Setup guide: Seen by receipt answered',
        sendTo: ['Mixpanel'],
        isActive: true,
        expirationDate: 'none',
    },
    SETUP_GUIDE_QUESTION_SHOULD_PARENTS_BE_ABLE_TO_SEND_MESSAGES_TO_OTHER_PARENTS_ANSWERED: {
        name: 'Setup guide: Parents messaging parents answered',
        sendTo: ['Mixpanel'],
        isActive: true,
        expirationDate: 'none',
    },
    SETUP_GUIDE_QUESTION_HOW_SHOULD_PARENTS_INFO_BE_DISPLAYED_ON_NEWS_FEED_ANSWERED: {
        name: 'Setup guide: Parent info on newsfeed answered',
        sendTo: ['Mixpanel'],
        isActive: true,
        expirationDate: 'none',
    },
    SETUP_GUIDE_QUESTION_CAN_PARENTS_DOWNLOAD_NEWS_FEED_PHOTOS_ANSWERED: {
        name: 'Setup guide: Parent photo download answered',
        sendTo: ['Mixpanel'],
        isActive: true,
        expirationDate: 'none',
    },
    SETUP_GUIDE_QUESTION_WHEN_SHOULD_STAFF_BE_AUTOMATICALLY_LOGGED_OUT: {
        name: 'Setup guide: Auto logout times answered',
        sendTo: ['Mixpanel'],
        isActive: true,
        expirationDate: 'none',
    },
    SETUP_GUIDE_QUESTION_ALLOW_USERS_TO_TRANSLATE_MESSAGES_AND_POST_ANSWERED: {
        name: 'Setup guide: Live translations answered',
        sendTo: ['Mixpanel'],
        isActive: true,
        expirationDate: 'none',
    },
    SETUP_GUIDE_QUESTION_SIDEKICK_ANSWERED: {
        name: 'Setup guide: Sidekick answered',
        sendTo: ['Mixpanel'],
        isActive: true,
        expirationDate: 'none',
    },
    SETUP_GUIDE_QUESTION_WHATS_THIS_CLICKED: {
        name: 'Setup guide: Whats this button clicked',
        sendTo: ['Mixpanel'],
        isActive: true,
        expirationDate: 'none',
    },
    SETUP_GUIDE_LOGO_UPLOADED: {
        name: 'Setup guide: Logo uploaded',
        sendTo: ['Mixpanel'],
        isActive: true,
        expirationDate: 'none',
    },
    SETUP_GUIDE_CLOSURE_DAYS_ADDED: {
        name: 'Setup guide: Closure days added',
        sendTo: ['Mixpanel'],
        isActive: true,
        expirationDate: 'none',
    },
    // User logged out
    INACTIVE_USER_LOGGED_OUT: {
        name: 'Inactive user logged out',
        sendTo: ['Mixpanel'],
        isActive: true,
        expirationDate: 'none',
    },

    // UK Tier 3 trial and UK Childminder trial key action tracking
    // These events are not representative of ALL key actions
    // Some events live in other crew's tracking event files
    // https://docs.google.com/spreadsheets/d/1zXcpVYIIDIO7zmC7mbPzt1V_Z2HEu0_6bxA8JVHCNYw/edit#gid=1516041757
    SIGN_IN_CHILD: {
        name: 'Sign in child',
        sendTo: ['Mixpanel'],
        isActive: true,
        expirationDate: 'none',
    },
    OPEN_POST_MODAL: {
        name: 'Open post modal',
        sendTo: ['Mixpanel'],
        isActive: true,
        expirationDate: 'none',
    },
    OPEN_ACCIDENT_REPORT: {
        name: 'Open accident report',
        sendTo: ['Mixpanel'],
        isActive: true,
        expirationDate: 'none',
    },
    NEW_PARENTAL_PERMISSION_OPENED: {
        name: 'New parental permission opened',
        sendTo: ['Mixpanel'],
        isActive: true,
        expirationDate: 'none',
    },
    NEW_ENQUIRY_STARTED: {
        name: 'New enquiry started',
        sendTo: ['Mixpanel'],
        isActive: true,
        expirationDate: 'none',
    },
    ADD_FORM_BUTTON_CLICKED: {
        name: 'Add form button clicked',
        sendTo: ['Mixpanel'],
        isActive: true,
        expirationDate: 'none',
    },
    OPEN_OBSERVATION: {
        name: 'Open observation',
        sendTo: ['Mixpanel'],
        isActive: true,
        expirationDate: 'none',
    },
    OPEN_ASSESSMENT: {
        name: 'Open assessment',
        sendTo: ['Mixpanel'],
        isActive: true,
        expirationDate: 'none',
    },
    NEW_ASSESSMENT_STARTED: {
        name: 'New assessment started',
        sendTo: ['Mixpanel'],
        isActive: true,
        expirationDate: 'none',
    },
    NEW_OBSERVATION_STARTED: {
        name: 'New observation started',
        sendTo: ['Mixpanel'],
        isActive: true,
        expirationDate: 'none',
    },
    ACTIVITY_LIBRARY_ITEM_OPENED: {
        name: 'Activity library item opened',
        sendTo: ['Mixpanel'],
        isActive: true,
        expirationDate: 'none',
    },
    ACTIVITY_PLANNER_GET_STARTED_CLICKED: {
        name: 'Activity planner "Get started" clicked',
        sendTo: ['Mixpanel'],
        isActive: true,
        expirationDate: 'none',
    },
    CREATE_CURRICULUM_CLICKED: {
        name: 'Create curriculum clicked',
        sendTo: ['Mixpanel'],
        isActive: true,
        expirationDate: 'none',
    },
    CREATE_PLAN_STARTED: {
        name: 'Create plan started',
        sendTo: ['Mixpanel'],
        isActive: true,
        expirationDate: 'none',
    },
    VIEW_INVOICE: {
        name: 'View invoice',
        sendTo: ['Mixpanel'],
        isActive: true,
        expirationDate: 'none',
    },
    ADD_CONTACT: {
        name: 'Add contact',
        sendTo: ['Mixpanel'],
        isActive: true,
        expirationDate: 'none',
    },
    CATEGORY_OPENED: {
        name: 'Navigation: Category opened',
        sendTo: ['Mixpanel'],
        isActive: true,
        expirationDate: 'none',
    },
    SETTINGS_GROUP_CLICKED: {
        name: 'Settings: setting group clicked',
        sendTo: ['Mixpanel'],
        isActive: true,
        expirationDate: 'none',
    },
    SETTINGS_LINK_CLICKED: {
        name: 'Settings: settings link clicked',
        sendTo: ['Mixpanel'],
        isActive: true,
        expirationDate: 'none',
    },
    SETTINGS_SUBLINK_CLICKED: {
        name: 'Settings: settings sublink clicked',
        sendTo: ['Mixpanel'],
        isActive: true,
        expirationDate: 'none',
    },
    MANAGE_ROLES_BUTTON_CLICKED: {
        name: 'Manage roles button clicked',
        sendTo: ['Mixpanel'],
        isActive: true,
        expirationDate: 'none',
    },
    MANAGE_AUTOMATIC_INVOICING_BUTTON_CLICKED: {
        name: 'Manage automatic invoicing button clicked',
        sendTo: ['Mixpanel'],
        isActive: true,
        expirationDate: 'none',
    },
    MANAGE_PRICING_BUTTON_CLICKED: {
        name: 'Manage pricing button clicked',
        sendTo: ['Mixpanel'],
        isActive: true,
        expirationDate: 'none',
    },
    MANAGE_IN_APP_PAYMENTS_BUTTON_CLICKED: {
        name: 'Manage in app payments button clicked',
        sendTo: ['Mixpanel'],
        isActive: true,
        expirationDate: 'none',
    },
    PRINT_CHILD_PROFILE_BUTTON_CLICKED: {
        name: 'Print child profile button clicked',
        sendTo: ['Mixpanel'],
        isActive: true,
        expirationDate: 'none',
    },
    // Events that are triggered by multiple actions (e.g., child sign in/out, child diaper change etc...)
    CHILD_ATTENDANCE_LOGS: {
        name: 'Child attendance logs',
        sendTo: ['Bucket'],
        isActive: true,
        expirationDate: 'none',
    },

    // Sales to onboarding flow (formerly sales-to-cs flow)
    S2O_FLOW_ONBOARDING_HUB_OPENED: {
        name: 'S2O: Onboarding hub opened',
        sendTo: ['Bucket', 'Mixpanel'],
        isActive: true,
        expirationDate: 'none',
    },
    S2O_FLOW_IN_APP_PAYMENTS_OPENED: {
        name: 'S2O: In app payments opened',
        sendTo: ['Bucket', 'Mixpanel'],
        isActive: true,
        expirationDate: 'none',
    },
    S2O_FLOW_IN_APP_PAYMENTS_STRIPE_ACCOUNT_CREATED: {
        name: 'S2O: In app payments - Stripe account created',
        sendTo: ['Bucket', 'Mixpanel'],
        isActive: true,
        expirationDate: 'none',
    },
    S2O_FLOW_IN_APP_PAYMENTS_EDIT_STRIPE_ACCOUNT_LINK_OPENED: {
        name: 'S2O flow: In app payments - Edit stripe account link opened',
        sendTo: ['Bucket', 'Mixpanel'],
        isActive: true,
        expirationDate: 'none',
    },
    // Billing hub (formerly Finance power page)
    BILLING_HUB_CHECKBOX_CLICKED: {
        name: 'Billing hub: checkbox clicked',
        sendTo: ['Mixpanel'],
        isActive: true,
        expirationDate: 'none',
    },
    BILLING_HUB_SIDE_PANEL_CLICKED: {
        name: 'Billing hub: side panel clicked',
        sendTo: ['Mixpanel'],
        isActive: true,
        expirationDate: 'none',
    },
    BILLING_HUB_TABLE_ROW_CLICKED: {
        name: 'Billing hub: table row clicked',
        sendTo: ['Mixpanel'],
        isActive: true,
        expirationDate: 'none',
    },
    BILLING_HUB_TOGGLE_BILLING_HUB: {
        name: 'Billing hub: toggle billing hub',
        sendTo: ['Mixpanel'],
        isActive: true,
        expirationDate: 'none',
    },
    // Simplified US billing plan (also known as chill bill)
    NEW_PLAN_SAVED: {
        name: 'New plan: saved',
        sendTo: ['Mixpanel'],
        isActive: true,
        expirationDate: 'none',
    },

    NEW_PLAN_MODAL_CANCELED: {
        name: 'New plan modal: canceled',
        sendTo: ['Mixpanel'],
        isActive: true,
        expirationDate: 'none',
    },

    NEW_PLAN_MODAL_MORE_OPTIONS: {
        name: 'New plan modal: more options',
        sendTo: ['Mixpanel'],
        isActive: true,
        expirationDate: 'none',
    },

    NEW_PLAN_MODAL_ADD_TUITION: {
        name: 'New plan modal: add tuition',
        sendTo: ['Mixpanel'],
        isActive: true,
        expirationDate: 'none',
    },

    NEW_PLAN_MODAL_ADD_FEE: {
        name: 'New plan modal: add fee',
        sendTo: ['Mixpanel'],
        isActive: true,
        expirationDate: 'none',
    },

    NEW_PLAN_MODAL_CUSTOM_PERIOD_SELECTED: {
        name: 'New plan modal: custom period selected',
        sendTo: ['Mixpanel'],
        isActive: true,
        expirationDate: 'none',
    },

    NEW_PLAN_CUSTOM_PERIOD_SAVED: {
        name: 'New plan: custom period saved',
        sendTo: ['Mixpanel'],
        isActive: true,
        expirationDate: 'none',
    },

    NEW_PLAN_CUSTOM_PERIOD_AMOUNT: {
        name: 'New plan: custom period amount',
        sendTo: ['Mixpanel'],
        isActive: true,
        expirationDate: 'none',
    },
} satisfies Record<string, TrackingEvent>;
