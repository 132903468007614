import type React from 'react';

type LogoComponentType = React.ComponentType<{isWhiteBg?: boolean}>;
export interface WhiteLabelConfiguration {
    ProductName: string;
    MobileAppUrl: string;
    PinFontColor: string;
    PinShadowColor: string;
    PinPillColor: string;
    PinPillEmptyColor: string;
    PrimaryColor: string;
    PrimaryBackgroundColor: string;
    SecondaryBackgroundColor: string;
    CircleAndDotColor: string;
    PinLogo: LogoComponentType;
    LoginLogo: LogoComponentType;
    LogoForWhiteBg: LogoComponentType;
    WithInstitutionImage: boolean;
    /**
     * We are adding custom colours for some whitelabels that, in the Famly app,
     * fall back to the neutral theme in case of pX00 colours (Espira and Nuffield).
     * In the sign in app we need better colours for them as they are used for the main theme
     * (e.g. p500 is set as the background colour across the app), and we don't want it to look so gray.
     */
    p500?: string;
    p400?: string;
    p300?: string;
    p200?: string;
}

// These names should match the 'WHITE_LABEL_XXXXX' features in the API.
export enum WhiteLabelName {
    WHITE_LABEL_ESPIRA = 'WHITE_LABEL_ESPIRA',
    WHITE_LABEL_KIDO = 'WHITE_LABEL_KIDO',
    WHITE_LABEL_BRIGHT_HORIZONS = 'WHITE_LABEL_BRIGHT_HORIZONS',
    WHITE_LABEL_KINDRED = 'WHITE_LABEL_KINDRED',
    WHITE_LABEL_MONKEY_PUZZLE = 'WHITE_LABEL_MONKEY_PUZZLE',
    WHITE_LABEL_GRANDIR_UK = 'WHITE_LABEL_GRANDIR_UK',
    WHITE_LABEL_NUFFIELD_HEALTH = 'WHITE_LABEL_NUFFIELD_HEALTH',
    WHITE_LABEL_N_FAMILY_CLUB = 'WHITE_LABEL_N_FAMILY_CLUB',
    WHITE_LABEL_KINDERZIMMER_UK = 'WHITE_LABEL_KINDERZIMMER_UK',
    WHITE_LABEL_CPC_TREEHOUSE = 'WHITE_LABEL_CPC_TREEHOUSE',
    WHITE_LABEL_CPC_ORCHARDS = 'WHITE_LABEL_CPC_ORCHARDS',
    WHITE_LABEL_BUSYBEES = 'WHITE_LABEL_BUSYBEES',
}
