import {useParams} from 'react-router-dom';
import React from 'react';
import PropTypes from 'prop-types';
import {List} from 'immutable';
import moment from 'moment-timezone';
import i18next from 'i18next';
import classNames from 'classnames';
import styled, {css} from 'styled-components';
import {Avatar, Icon, Stack} from 'modern-famly';

import IconSwitcher from 'web-app/react/components/icons/legacy/ember-icon-switcher';
import {LockerRoomWashHands, House} from 'web-app/react/components/icons/streamline';
import {RegisterTemperature} from 'web-app/react/components/icons/custom';
import {IconWeight} from 'web-app/react/components/icons/types';
import {
    mealTypeColorToColorKey,
    mealTypeColorFromId,
} from 'web-app/react/institution/settings/meal-settings/meal-types/helpers';
import * as IntercomConstants from 'web-app/services/intercom-constants';
import {ACTION_TYPES} from 'web-app/react/profile/child-profile/activity/constants';
import {Colors} from 'web-app/styleguide/colors';
import {hasValue} from 'web-app/util/typescript';
import {useMarkupString} from 'web-app/react/hooks/use-markup-string';
import {useInvertIconColors} from 'web-app/react/hooks/use-invert-icon-colors';
import {type NonEmptyArray, isNonEmpty} from 'web-app/util/non-empty-array';
import {s1, getSpacing} from 'web-app/styleguide/spacing';
import {getLeaveTypeIcon, LeaveTypeIconSize} from 'web-app/react/leave-types/leave-type-icon';
import {LeaveType} from 'web-app/react/leave-types/leave-type';
import {Allergies, DietaryRestrictions} from 'web-app/react/components/icons/custom';
import {type MealTypeFragment} from 'web-app/react/meals/meal-registration/__generated__/queries.api-types';
import {type PersonStatus} from 'web-app/react/staffing-attendance/hooks/use-adapt-staff-hours-to-person-status-map';
import {type MedicationFormForPeopleOverviewFragment} from 'web-app/react/institution-people/__generated__/queries.api-types';
import {colorCodeToAvatarColor} from 'web-app/react/entities/common/image';

import {MedicationFormRenderer} from './person-button-components';
import {PersonButtonStatus} from './person-button-status';
import {useCurrentPersonStatus} from '../people-section/use-current-person-status';
import {useGetPersonCurrentRoomId} from './get-person-current-room';
import {getPersonLastCheckin} from './get-person-last-checkin';
import {useGetCurrentAndBaseGroupName} from './use-get-current-and-base-group-name';
import {useIsInAnotherRoom} from './use-is-in-another-room';
import {useGoingHomeWith} from './use-going-home-with';
import {RoomStatusPills} from './room-status-pills';
import {personButtonE2eClass} from './person-button-e2e-class';

/**
 * Apparently the prop `person.checkIns` is some times array and other times List.
 * I think there's a difference between the staff and child model. I could give a TED
 * talk on what is fundamentally wrong with out setup for this to happen. But I'll
 * just monkey-patch it, cry in the bathroom, and forget all about it.
 *
 * This is just an easy fix for that 🙄
 */
const isNonEmptyArrayOrList = (candidate: List<any> | any[]) => {
    if (List.isList(candidate)) {
        return candidate.size > 0;
    }
    return isNonEmpty(candidate);
};

const classPrefix = 'PERSON_BUTTON';
export const PERSON_BUTTON_CLASSNAMES = {
    name: `${classPrefix}-name`,
    imageHolder: `${classPrefix}-image-holder`,
    image: `${classPrefix}-image`,
    statusItem: `${classPrefix}-status-item`,
};

export const StyledPersonButton = styled.div<{
    avatarSize: number;
    hideExtras?: boolean;
    selected?: boolean;
    transparentSelection?: boolean;
}>`
    display: block;
    position: relative;
    width: 136px;
    box-sizing: border-box;
    border-radius: 4px;
    text-align: center;
    cursor: pointer;

    ${props =>
        props.hideExtras
            ? css`
                  height: 168px;
              `
            : css`
                  height: 216px;

                  &:before {
                      content: '';
                      width: 100%;
                      height: 100%;
                      position: absolute;
                      left: 0;
                      top: 0;
                  }
              `}

    .${PERSON_BUTTON_CLASSNAMES.name} {
        margin-top: 12px;
        width: 100%;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        color: ${props => props.theme.mf.colorPalette.n400};
        font-size: ${props => props.theme.fontConfiguration.sizes.Body};
        font-weight: 600;
        line-height: 20px;

        opacity: 0.7;
        &[data-selected],
        &[data-active] {
            opacity: 1;
        }

        &[data-faded='true'][data-selected='false'] {
            opacity: 0.5;
        }
    }

    .${PERSON_BUTTON_CLASSNAMES.image} {
        border-radius: 50%;
        width: 100%;
        height: 100%;
        &[data-active='false'] {
            opacity: 0.3;
            filter: grayscale(100%);
        }
    }

    .${PERSON_BUTTON_CLASSNAMES.imageHolder} {
        margin: 0 auto;
        position: relative;
        background-color: ${props => props.theme.mf.colorPalette.n50};
        text-align: center;
        border-radius: 50%;
        width: ${props => props.avatarSize}px;
        height: ${props => props.avatarSize}px;

        &[data-selected='true'] {
            opacity: 1 !important;
        }

        &[data-faded='true'] {
            opacity: 0.5;
        }
    }
`;

const TickContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: ${props => props.theme.mf.colorPalette.p300};
    position: absolute;
    top: 82px;
    right: 17px;
    width: 30px;
    height: 30px;
    z-index: 2;
`;

const markupToBackgroundString = (markup: string) => `url("data:image/svg+xml,${markup}")`;
const propsToCacheKey = ({icon, color, transparent, size}) => `${icon}_${color}_${transparent}_${size}`;

const RenderIcon: React.FC<RenderIconProps> = React.memo(
    ({backgroundMarkup, className, 'data-e2e-class': dataE2eClass}) => (
        <div
            className={className}
            style={{
                backgroundImage: markupToBackgroundString(backgroundMarkup),
                backgroundSize: 'contain',
                backgroundPosition: 'center',
            }}
            data-e2e-class={dataE2eClass}
        />
    ),
);
interface RenderIconProps {
    backgroundMarkup: string;
    className?: string;
    'data-e2e-class'?: string;
}

interface OptimizedIconSwitcherProps {
    className?: string;
    'data-e2e-class'?: string;
    icon?: any;
    color?: any;
    transparent?: any;
    size?: any;
    fill?: any;
}

const OptimizedIconSwitcher = (props: OptimizedIconSwitcherProps) => {
    const {className, 'data-e2e-class': dataE2eClass, icon, color, transparent, size, fill} = props;

    const iconMarkup = useMarkupString(
        IconSwitcher,
        {
            className,
            icon,
            color,
            transparent,
            size,
            fill,
        },
        true,
        propsToCacheKey,
    );

    // For icons that are transparent or white on coloured background, we want to invert the colours so that the custom statuses don't cover the whole child photo
    // See https://famlyapp.atlassian.net/browse/FBS-7473
    const processedMarkup = useInvertIconColors(iconMarkup, icon, color);

    return <RenderIcon backgroundMarkup={processedMarkup} className={props.className} data-e2e-class={dataE2eClass} />;
};

export const StyledStatusImage = styled(OptimizedIconSwitcher)`
    height: 62px;
    width: 62px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
`;

const CenteredStatusImage = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
`;

const ActionItemIcon = styled(OptimizedIconSwitcher)`
    width: 14px;
    height: 14px;
    display: block;
    margin: 3px auto;
`;

// In the normal case
const amountOfActionItemsAroundCircle = 16;
const degreesPerItem = 360 / amountOfActionItemsAroundCircle;
const distanceToCenter = 58;

// For historical reasons, the first item should be at -45 degrees
const startingItemOffset = -45;
const startingDegrees = 360 + startingItemOffset;

const ActionItemOverflow = styled.div`
    width: 20px;
    height: 20px;
    margin: 0 auto;
`;

const StyledActionItem = styled.div<{color?: any; index: number}>`
    width: 20px;
    height: 20px;
    box-sizing: border-box;
    background-color: #ccc;
    border-radius: 50%;
    float: left;

    position: absolute;
    top: 50%;
    left: 50%;

    --distance: -${distanceToCenter}px;

    ${props => {
        const bigDegrees = (startingDegrees + props.index * degreesPerItem) % 360;
        return css`
            transform: translate(-50%, -50%) rotate(${bigDegrees}deg) translate(0, var(--distance))
                rotate(-${bigDegrees}deg);
        `;
    }}

    &[data-action='${ACTION_TYPES.MEAL}'] {
        background-color: ${props => props.theme.analogue2};
    }
    &[data-action='${ACTION_TYPES.DIAPERCHANGE}'],
    &[data-action='${ACTION_TYPES.TOILETVISIT}'],
    &[data-action='${ACTION_TYPES.DIAPER_TOILET_ACCIDENT}'] {
        background-color: ${props => props.theme.mf.colorPalette.n300};
        &[data-diapering-type='WEE'],
        &[data-diapering-type='WET'] {
            background-color: ${props => props.theme.mf.colorPalette.y400};
        }
        &[data-diapering-type='BM'] {
            background-color: ${props => props.theme.mf.colorPalette.p300};
        }
        &[data-diapering-type='WET_BM'],
        &[data-diapering-type='WET_SOILED'] {
            background-color: ${props => props.theme.mf.colorPalette.o400};
        }
    }
    &[data-action='${ACTION_TYPES.TEMPERATURE_TAKEN}'] {
        background-color: ${props => props.theme.accent1};
    }
    &[data-action='${ACTION_TYPES.CREAM_APPLICATION}'] {
        background-color: ${props => props.theme.analogue4};
    }
    &[data-action='${ACTION_TYPES.WASHED_HANDS}'] {
        background-color: ${props => props.theme.analogue3};
    }
    &[data-action='${ACTION_TYPES.SLEEP_CHECK}'] {
        background-color: ${props => props.theme.accent2};
    }
    &[data-action='${ACTION_TYPES.MEAL}'] {
        background-color: ${props => props.theme[props.color]} !important;
    }
    &[data-action='${ACTION_TYPES.OVERFLOW}'] {
        background-color: ${props => props.theme.mf.colorPalette.n200};
    }
`;

const StyledActionImage = styled.img`
    width: 14px;
    height: 14px;
    display: block;
    margin: 3px auto;
`;

const StyledImageDarkening = styled.div`
    top: 0;
    left: 0;
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    border-radius: 50%;
`;

const StyledNameImage = styled.img`
    width: 13px;
    position: relative;
    top: -3px;
`;

const StyledActivity = styled.ul`
    font-size: ${props => props.theme.fontConfiguration.sizes.Small};
    font-family: ${props => `Inter, ${props.theme.fontConfiguration.family}`};
    color: #999;
`;

const StyledActivityIcon = styled.img`
    vertical-align: text-top;
    width: 15px;
    height: 15px;
`;

const StyledCheckin = styled.ul<{showAsActive?: boolean}>`
    font-size: ${props => props.theme.fontConfiguration.sizes.Small};
    font-family: ${props => `Inter, ${props.theme.fontConfiguration.family}`};
    color: #999;
    line-height: 20px;

    ${props =>
        props.showAsActive
            ? css`
                  opacity: 1;
                  display: inline-block;
              `
            : ''}
`;

const StyledCheckoutStatusCircle = styled.div<{checkoutOverdue: boolean}>`
    display: inline-block;
    border-radius: 50%;
    width: 10px;
    height: 10px;
    background: ${props => (props.checkoutOverdue ? props.theme.accent3 : props.theme.accent2)};
    margin-right: 3px;
`;

const HiddenIfLast = styled.span`
    &:last-child {
        display: none;
    }
`;

const StyledHouseIcon = styled(House)`
    margin-right: ${getSpacing(s1)};
`;

const StyledAllergiesIcon = styled.div`
    display: inline-block;
    vertical-align: bottom;
    max-height: 22px;
    margin-right: ${getSpacing(s1)};
`;

export const AllergiesIconTypes = {
    ALLERGY: 'allergy',
    DIETARY_CONSIDERATIONS: 'dietaryConsiderations',
};

export const AllergiesIcon = ({type, className}) => {
    return (
        <StyledAllergiesIcon className={className}>
            {type === AllergiesIconTypes.ALLERGY ? (
                <Allergies
                    fill={{
                        type: 'mf',
                        color: 'r400',
                    }}
                    /* Size of the icons in Figma is not the same, so I'm adjusting them temporarily with size prop */
                    size={22}
                />
            ) : (
                <DietaryRestrictions
                    fill={{
                        type: 'mf',
                        color: 'b400',
                    }}
                    /* Size of the icons in Figma is not the same, so I'm adjusting them temporarily with size prop */
                    size={20}
                />
            )}
        </StyledAllergiesIcon>
    );
};

AllergiesIcon.propTypes = {
    type: PropTypes.oneOf(Object.values(AllergiesIconTypes)),
    className: PropTypes.string,
};

const RegisterDiaperIcon = ({src}: {src: string}) => {
    return (
        <Stack alignItems="center" justifyContent="center" height="100%">
            <img width="10px" src={src} />
        </Stack>
    );
};

// Converts an action item to JSX in the context of the available meal types
const actionToJSX = (mealTypes?: MealTypeFragment[]) => (action, i) =>
    (
        <StyledActionItem
            key={action.actionId}
            className={PERSON_BUTTON_CLASSNAMES.statusItem}
            index={i}
            data-action={action.actionType}
            data-diapering-type={action.payload?.diaperingType}
            color={
                mealTypes &&
                action.mealTypeId &&
                mealTypeColorToColorKey(mealTypeColorFromId(mealTypes, action.mealTypeId))
            }
        >
            {action.actionType === ACTION_TYPES.DIAPERCHANGE && (
                <RegisterDiaperIcon src="img/register-diaper-icons/diaper.svg" />
            )}
            {action.actionType === ACTION_TYPES.TOILETVISIT && (
                <RegisterDiaperIcon src="img/register-diaper-icons/toilet.svg" />
            )}
            {action.actionType === ACTION_TYPES.DIAPER_TOILET_ACCIDENT && (
                <RegisterDiaperIcon src="img/register-diaper-icons/accident.svg" />
            )}
            {action.actionType === ACTION_TYPES.CREAM_APPLICATION && (
                <ActionItemIcon icon="Lotion" fill="white" size={14} />
            )}
            {action.actionType === ACTION_TYPES.TEMPERATURE_TAKEN && (
                <Stack alignItems="center" justifyContent="center">
                    <RegisterTemperature size={16} fill={Colors.InvertedText} />
                </Stack>
            )}
            {action.actionType === ACTION_TYPES.WASHED_HANDS && (
                <Stack alignItems="center" justifyContent="center">
                    <LockerRoomWashHands size={13} fill={Colors.InvertedText} />
                </Stack>
            )}
            {action.actionType === ACTION_TYPES.BIKE && <StyledActionImage src="img/icons/bike_white.png" />}
            {action.actionType === ACTION_TYPES.MEAL && (
                <ActionItemIcon icon="Meal" fill="white" size={14} transparent />
            )}
            {action.actionType === ACTION_TYPES.SLEEP_CHECK && (
                <ActionItemIcon icon="Nap" fill="white" size={14} transparent />
            )}
            {action.actionType === ACTION_TYPES.OVERFLOW && (
                <>
                    <ActionItemOverflow>
                        <Icon name="more_horiz" color="n400" size={14} />
                    </ActionItemOverflow>
                </>
            )}
        </StyledActionItem>
    );

export type CheckinsType = List<any> | any[] | undefined; // @todo: not sure about the type here

enum CheckoutTypes {
    SelfCheckout = 'SelfCheckout',
    MustCheckout = 'MustCheckout',
}

type Name = {
    salutation?: any;
    firstName: string;
    middleName: string;
    lastName: string;
    fullName: string;
};

interface Person {
    id?: string;
    hasChildSick?: boolean;
    isSick?: boolean;
    hasVacation?: boolean;
    isAbsent?: boolean;
    isSleeping?: boolean;
    naps?: any;
    pickupTime?: any;
    pickupName?: any;
    checkoutType?: any;
    hasBirthday?: boolean;
    checkedIn?: boolean;
    checkins: CheckinsType;
    checkoutTitle?: string;
    name: Name;
    statusRegistrations?: any;
    actions?: any;
    mealRegistrations?: any;
    groupId: string;
    attending?: any;
    image?: any;
}
interface PersonButtonProps {
    person: Person;
    hideExtras?: boolean;
    transparentSelection?: boolean;
    selected?: boolean;
    onClick?: () => void;
    timezone: string;
    alwaysShowCheckIns?: boolean;
    allGroups?: {groupId: string; title?: string | undefined}[];
    faded?: boolean;
    showAsActive?: boolean;
    baseGroupName?: string;
    inAnotherRoom?: boolean;
    fromAnotherRoom?: boolean;
    currentGroupName?: string;
    className?: string;
    hidePickupInfo?: boolean;
    hideName?: boolean;
    mealTypes?: MealTypeFragment[];
    hasAllergies?: boolean;
    hasDietaryConsiderations?: boolean;
    showWentHome?: boolean;
    medicationForms?: NonEmptyArray<MedicationFormForPeopleOverviewFragment> | null;
    statusForTheDay?: PersonStatus;
    groupIds?: string[];
    childImageSize?: number;
    institutionId: string;
    avatarSize?: number;
    ['data-intercom-target']?: string;
}

export const PersonButtonBase: React.FC<React.PropsWithChildren<PersonButtonProps>> = ({
    person,
    transparentSelection,
    hideExtras,
    selected,
    timezone,
    alwaysShowCheckIns,
    onClick,
    faded,
    showAsActive,
    className,
    hidePickupInfo,
    hideName,
    mealTypes,
    hasAllergies = false,
    hasDietaryConsiderations = false,
    showWentHome,
    medicationForms,
    statusForTheDay,
    groupIds,
    allGroups,
    ['data-intercom-target']: dataIntercomTarget,
    avatarSize = 104,
}) => {
    const {
        id = '',
        hasChildSick,
        isSick,
        hasVacation,
        isAbsent,
        isSleeping,
        naps,
        pickupTime,
        pickupName,
        checkoutType,
        hasBirthday,
        checkedIn,
        checkins,
        checkoutTitle,
        name,
        statusRegistrations,
        actions,
        mealRegistrations,
        // This is the group that the practitioner belongs to
        groupId,
        attending,
        image,
    } = person;

    const [combinedActions, longActionList] = React.useMemo(() => {
        // Concatenating meal registrations to all other actions. Ideally in the future these two action lists are merged chronologically,
        // but that requires either the backend to do it or the backend to provide createdAt timestamps for meal registrations
        const transformedMealRegistrations = ((mealRegistrations && mealRegistrations.toArray()) || []).map(meal => ({
            actionId: meal.id,
            actionType: 'MEAL',
            ...meal,
        }));

        const combinedActions = (actions || []).concat(transformedMealRegistrations);
        const longActionList = combinedActions.slice(0, amountOfActionItemsAroundCircle - 2);

        const overflowActionItem = {
            actionId: 'OVERFLOW',
            actionType: 'OVERFLOW',
        };

        if (combinedActions.length > amountOfActionItemsAroundCircle - 1) {
            longActionList.push(overflowActionItem);
        }

        return [combinedActions, longActionList];
    }, [actions, mealRegistrations]);

    const renderActionList = !hideExtras && hasValue(combinedActions) && isNonEmptyArrayOrList(combinedActions);

    // This is the id the user is currently checked in to
    const currentRoom = useGetPersonCurrentRoomId(person.checkins);

    const {currentGroupName, baseGroupName} = useGetCurrentAndBaseGroupName(allGroups, currentRoom, groupId);

    const imageColorCode = image?.colorCode;

    const avatarColor = colorCodeToAvatarColor(imageColorCode);

    const imageUrl = getImageUrl(person);
    const noProfileImage = !imageUrl || (image?.isEmpty ?? false);

    const hasStatus = isSick || hasVacation || isAbsent || hasChildSick;
    const initials = getInitials(name);

    const expectedList =
        attending && attending.from && !hasVacation ? getExpectedList(attending.sessions, person.checkins) : null;

    const now = timezone ? moment.tz(timezone) : moment.tz();
    const checkinThreshold = now.clone().add(30, 'minutes');
    const shouldCheckoutSoon =
        checkedIn &&
        (checkoutType === CheckoutTypes.SelfCheckout || checkoutType === CheckoutTypes.MustCheckout) &&
        pickupTime &&
        moment(pickupTime).isSameOrBefore(checkinThreshold);

    const smallImage = imageUrl || '';
    const checkoutOverdue = pickupTime && now.isAfter(moment(pickupTime));

    const wentHome = getWentHome(person.checkins);

    const inAnotherRoom = useIsInAnotherRoom({
        groupId,
        allGroups,
        checkins: person.checkins,
    });

    const fromAnotherRoom = Boolean(
        groupIds && currentRoom && !inAnotherRoom && currentRoom !== groupId && groupIds.includes(currentRoom),
    );

    const renderCheckInList =
        (hasValue(expectedList) && isNonEmptyArrayOrList(expectedList)) ||
        (!hidePickupInfo && pickupTime) ||
        ((alwaysShowCheckIns || !checkedIn) && hasValue(checkins) && isNonEmptyArrayOrList(checkins));

    const currentPersonStatus = useCurrentPersonStatus({
        personStatus: statusForTheDay,
        deprecatedStatus: {
            isSick: isSick || false,
            hasVacation: hasVacation || false,
            hasChildSick: hasChildSick || false,
            isAbsent: isAbsent || false,
        },
        timezone,
    });

    const childSickImage = React.useMemo(() => {
        if (currentPersonStatus[LeaveType.ChildSick][0]) {
            return (
                <CenteredStatusImage>
                    {getLeaveTypeIcon(LeaveType.ChildSick, LeaveTypeIconSize.huge)}
                </CenteredStatusImage>
            );
        }
        return null;
    }, [currentPersonStatus]);

    const sickImage = React.useMemo(() => {
        if (currentPersonStatus[LeaveType.Sick][0]) {
            return (
                <CenteredStatusImage data-e2e-class={'report-sick-button'}>
                    {getLeaveTypeIcon(LeaveType.Sick, LeaveTypeIconSize.huge)}
                </CenteredStatusImage>
            );
        }
        return null;
    }, [currentPersonStatus]);

    const vacationImage = React.useMemo(() => {
        if (currentPersonStatus[LeaveType.Vacation][0]) {
            return (
                <CenteredStatusImage>
                    {getLeaveTypeIcon(LeaveType.Vacation, LeaveTypeIconSize.huge)}
                </CenteredStatusImage>
            );
        }
        return null;
    }, [currentPersonStatus]);

    const absentImage = React.useMemo(() => {
        if (currentPersonStatus[LeaveType.Absent][0]) {
            return (
                <CenteredStatusImage>{getLeaveTypeIcon(LeaveType.Absent, LeaveTypeIconSize.huge)}</CenteredStatusImage>
            );
        }
        return null;
    }, [currentPersonStatus]);

    const latestCheckIn = useLatestRelevantCheckin(checkins);
    const isCheckedOut = React.useMemo(() => latestCheckIn && latestCheckIn.checkoutTime, [latestCheckIn]);
    const goingHomeWithChildName = useGoingHomeWith({
        goHomeWithChildId: latestCheckIn?.goHomeWithChildId,
    });

    return (
        <StyledPersonButton
            selected={selected}
            transparentSelection={transparentSelection}
            onClick={onClick}
            hideExtras={hideExtras}
            className={classNames(
                'personButton',
                {['personButtonSelected']: selected},
                checkedIn ? 'checkedIn' : null,
                className,
            )}
            avatarSize={avatarSize}
            data-intercom-target={
                hasValue(dataIntercomTarget) ? dataIntercomTarget : IntercomConstants.TARGET_IDS.PERSON_BUTTON
            }
            data-e2e-class={personButtonE2eClass}
            data-e2e-id={`person-button-${id}`}
        >
            {selected ? (
                <TickContainer data-e2e-class="selected-tick">
                    <Icon name="done" size={22} color="n0" filled />
                </TickContainer>
            ) : null}

            <div
                className={PERSON_BUTTON_CLASSNAMES.imageHolder}
                data-selected={selected}
                data-active={showAsActive}
                data-faded={faded}
            >
                {hasStatus ? null : (
                    <Avatar
                        initials={initials}
                        size={avatarSize}
                        disabled={!showAsActive}
                        src={noProfileImage ? undefined : smallImage}
                        color={avatarColor}
                    />
                )}
                {childSickImage}
                {sickImage}
                {vacationImage}
                {absentImage}
                {isSleeping && (
                    <React.Fragment>
                        <StyledImageDarkening />
                        <StyledStatusImage icon="Nap" size={62} transparent />
                    </React.Fragment>
                )}
                {statusRegistrations &&
                    statusRegistrations.map(
                        ({statusConfig}, i) =>
                            statusConfig.icon && (
                                <React.Fragment key={i}>
                                    <StyledImageDarkening />
                                    <StyledStatusImage
                                        icon={statusConfig.icon}
                                        color={statusConfig.color}
                                        size={62}
                                        transparent
                                    />
                                </React.Fragment>
                            ),
                    )}
                <div>{renderActionList && longActionList && longActionList.map(actionToJSX(mealTypes))}</div>
            </div>
            {!hideName && (
                <div
                    data-selected={selected}
                    data-active={showAsActive}
                    data-faded={faded}
                    className={`name ${PERSON_BUTTON_CLASSNAMES.name}`}
                    data-e2e-id="person-button-name"
                >
                    {hasBirthday && <StyledNameImage src="img/icons/balloons.png" alt="" />}
                    {showWentHome && wentHome ? <StyledHouseIcon size={16} weight={IconWeight.Bold} /> : null}
                    {checkedIn && hasAllergies ? <AllergiesIcon type="allergy" className="overlapping" /> : null}
                    {checkedIn && hasDietaryConsiderations ? (
                        <AllergiesIcon type="dietary" className="overlapping" />
                    ) : null}
                    {name.firstName}
                </div>
            )}

            {hasValue(medicationForms) ? (
                <MedicationFormRenderer medicationForms={medicationForms} timezone={timezone ? timezone : ''} />
            ) : null}

            {statusForTheDay ? (
                <PersonButtonStatus
                    isSick={currentPersonStatus[LeaveType.Sick]}
                    hasVacation={currentPersonStatus[LeaveType.Vacation]}
                    hasChildSick={currentPersonStatus[LeaveType.ChildSick]}
                    isAbsent={currentPersonStatus[LeaveType.Absent]}
                />
            ) : null}

            {!hideExtras && (
                <React.Fragment>
                    <RoomStatusPills
                        fromAnotherRoom={fromAnotherRoom}
                        inAnotherRoom={inAnotherRoom}
                        baseGroupName={baseGroupName}
                        currentGroupName={currentGroupName}
                    />

                    {renderCheckInList ? (
                        <StyledCheckin>
                            {(alwaysShowCheckIns || !checkedIn) && latestCheckIn && (
                                <li>
                                    {latestCheckIn.checkinTime && (
                                        <React.Fragment>
                                            <StyledActivityIcon src="img/icons/checkin_bfbfbf.png" />
                                            <span data-e2e-class="check-in-time">{`${getDate(
                                                latestCheckIn.checkinTime,
                                                timezone,
                                            )} - `}</span>
                                        </React.Fragment>
                                    )}
                                    {latestCheckIn.checkoutTime ? (
                                        <span data-e2e-class="check-out-time">{`${getDate(
                                            latestCheckIn.checkoutTime,
                                            timezone,
                                        )}`}</span>
                                    ) : (
                                        latestCheckIn.estimatedCheckoutTime && (
                                            <span>{`${getDate(latestCheckIn.estimatedCheckoutTime, timezone)}`}</span>
                                        )
                                    )}
                                </li>
                            )}
                            {!hidePickupInfo && !isCheckedOut ? (
                                <li>
                                    {pickupTime ? (
                                        <React.Fragment>
                                            {shouldCheckoutSoon && (
                                                <StyledCheckoutStatusCircle checkoutOverdue={checkoutOverdue} />
                                            )}
                                            <span>{getDate(pickupTime, timezone)}</span>
                                            <HiddenIfLast>,&nbsp;</HiddenIfLast>
                                            {pickupName && (
                                                <span>{`${i18next.t('group_tag.pickupBy')} ${pickupName}`}</span>
                                            )}
                                            {checkoutType === CheckoutTypes.SelfCheckout && (
                                                <span> {i18next.t('group_tag.selfCheckout')}</span>
                                            )}
                                            {checkoutType === CheckoutTypes.MustCheckout && (
                                                <span> {checkoutTitle}</span>
                                            )}
                                        </React.Fragment>
                                    ) : null}
                                    {!pickupTime && pickupName ? pickupName : null}
                                    {!pickupTime && !pickupName && goingHomeWithChildName ? (
                                        <span>{`${i18next.t('group_tag.goingWith')} ${goingHomeWithChildName}`}</span>
                                    ) : null}
                                </li>
                            ) : undefined}
                            {expectedList &&
                                expectedList.map((expected, i) => (
                                    <li key={i}>
                                        <span>{`${i18next.t('group_tag.expectedBy')}: ${getDate(
                                            expected.signIn,
                                            timezone,
                                        )} - ${getDate(expected.signOut, timezone)}`}</span>
                                    </li>
                                ))}
                        </StyledCheckin>
                    ) : null}
                    {naps && naps.length > 0 && (
                        <StyledActivity>
                            {naps.map((nap, i) => (
                                <li key={i}>
                                    <StyledActivityIcon src="img/icons/sleep_gray.png" />
                                    {`${getDate(nap.startTime, timezone)} - ${getDate(nap.endTime, timezone)}`}
                                </li>
                            ))}
                        </StyledActivity>
                    )}
                </React.Fragment>
            )}
        </StyledPersonButton>
    );
};

const getInitials = (name: Name) => {
    let initials = '';
    initials += name.firstName.length > 0 ? name.firstName[0] : '';
    initials += name.lastName && name.lastName.length > 0 ? name.lastName[0] : '';
    return initials;
};

const getDate = (date: any, timezone?: string) => {
    let resultDate = '';

    if (date) {
        if (timezone) {
            resultDate = moment.tz(date, timezone).format('LT');
        } else {
            resultDate = moment(date).format('LT');
        }
    }

    return resultDate;
};

const getExpectedList = (sessions, checkins) => {
    const checkedIn: boolean = checkins.some(checkin => checkin.checkoutTime === null);

    // if the user is still checked in, we need to show all sessions
    if (checkedIn) {
        return sessions;
    } else {
        const lastCheckout = [...checkins]
            .filter(checkin => checkin.checkoutTime)
            .sort((a, b) => a.checkoutTime - b.checkoutTime)
            .at(-1);

        // otherwise, we check when the child was last signed off and return
        // all sessions, that start after that last sign off occurred
        if (lastCheckout) {
            return sessions.filter(session => moment(lastCheckout.checkoutTime).isBefore(session.signIn));
        }
    }

    // if they are neither checked in, nor have finished sign ins, we show all
    return sessions;
};

const getImageUrl = (person, fallbackImageSrc?: string) => {
    const {image, profileImage} = person;

    if (profileImage) {
        return profileImage.url;
    } else if (image) {
        return image.small;
    } else if (fallbackImageSrc) {
        return fallbackImageSrc;
    } else {
        // Works only with the main app, use `fallbackImageSrc` in other project
        return 'img/icons/empty-profile.jpg';
    }
};

export const getWentHome = (checkins: CheckinsType) => {
    const lastCheckin = getPersonLastCheckin(checkins);
    return Boolean(lastCheckin && lastCheckin.checkoutTime !== null);
};

function useLatestRelevantCheckin(checkins: CheckinsType) {
    const {type, id: currentlyLookedAtGroup} = useParams<{type: string; id: string}>();
    return React.useMemo(
        () => getLatestRelevantCheckin(checkins, type, currentlyLookedAtGroup),
        [checkins, currentlyLookedAtGroup, type],
    );
}

export function getLatestRelevantCheckin(checkins, type, currentlyLookedAtGroup) {
    if (type !== 'group' || !currentlyLookedAtGroup) {
        return getPersonLastCheckin(checkins);
    }
    return getPersonLastCheckin(checkins.filter(filterForCurrentlyLookedAtRoomPredicate(currentlyLookedAtGroup)));
}

export function filterForCurrentlyLookedAtRoomPredicate(currentlyLookedAtGroup) {
    return checkin => checkin.groupId === currentlyLookedAtGroup;
}
